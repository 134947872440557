import axios from "axios"
import Cookies from "js-cookie"

const BASE_URL = process.env.NEXT_BASE_URL
const callRefreshToken = async (refresh_token) => {
	const postData = new FormData()
	postData.append("refresh_token", refresh_token)
	return axios
		.create({ baseURL: BASE_URL, responseType: "json" })
		.post("auth/refreshtoken", postData)
		.then((res) => {
			const { access_token, refresh_token } = res.data
			Cookies.set("access_token", access_token, { secure: true, domain: CookiesDomain })
			Cookies.set("refresh_token", refresh_token, { secure: true, domain: CookiesDomain })
		})
}

const AuthInInterceptor = (axiosObj) => {
	axiosObj.interceptors.request.use(
		(req) => {
			req.headers.Authorization = "Bearer " + Cookies.get("access_token")
			return req
		},
		(err) => Promise.reject(err)
	)

	axiosObj.interceptors.response.use(
		(res) => res,
		async (err) => {
			const oriReq = err.config
			const status = err.response.status
			if ([401, 500].includes(status) && !oriReq._retry) {
				oriReq._retry = true
				await callRefreshToken(Cookies.get("refresh_token"))
				return axiosObj(oriReq)
			}
			return Promise.reject(err.response)
		}
	)

	return axiosObj
}

// API 1toko
export const API = axios.create({ baseURL: BASE_URL, responseType: "json" })
export const APIAuth = AuthInInterceptor(axios.create({ baseURL: BASE_URL, responseType: "json" }))
export const APIAuthFile = AuthInInterceptor(
	axios.create({
		baseURL: BASE_URL,
		headers: { "content-type": "multipart/form-data" },
		responseType: "json",
	})
)

export const APITrans = AuthInInterceptor(
	axios.create({
		baseURL: process.env.NEXT_BASE_URL_TRANS,
		responseType: "json",
	})
)
export const APIPayment = AuthInInterceptor(
	axios.create({
		baseURL: process.env.NEXT_BASE_URL_PAYMENT,
		responseType: "json",
	})
)
export const APICourier = AuthInInterceptor(
	axios.create({
		baseURL: process.env.NEXT_BASE_URL_COURIER,
		responseType: "json",
	})
)
export const APIChat = AuthInInterceptor(
	axios.create({
		baseURL: process.env.NEXT_BASE_URL_CHAT,
		responseType: "json",
	})
)
export const APIImg = axios.create({
	baseURL: process.env.NEXT_BASE_URL_UPLOAD_IMG,
	headers: { "content-type": "multipart/form-data" },
	responseType: "json",
})

export default API
