"use client"

import { useEffect } from 'react';
import style from '../../app/styles/header.module.css'

export default function HeaderPage() {
    useEffect(() => { }, [])
    return (
        <div className={style.container}>
            header component
        </div>
    );
}