"use client"

import useStores from '@/app/store/storeZustand';
import { isDomain, WEBLINK } from '@/app/utils/globalController';
import { useEffect } from 'react';

export default function InitComponent({ children }) {
    const store_link = isDomain ? WEBLINK.replace("www.", "") : WEBLINK.replace("www.", "").replace(`.${DOMAIN}`, "")
    const setStore = useStores(state => state.setStore)

    useEffect(() => { setStore(store_link) }, [store_link])

    return children;
}