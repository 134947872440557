import { globalStorage } from "@/app/store/storage"
import API from "@/app/utils/API"
import { create } from "zustand"
import { persist } from "zustand/middleware"

const useStores = create(
	persist(
		(set) => ({
			store: undefined,
			setStore: async (id) => {
				const store = await API.post(`seller/info`, { id })
					.then((res) => res.data)
					.catch((err) => undefined)
				set((state) => ({ ...state, store }))
			},
		}),
		{ name: "STR", storage: globalStorage, partialize: (state) => ({ store: state.store }) }
	)
)

export default useStores
